.navigation {
   .btn {
      box-shadow: none;
   }
}
.navbar-brand .navbar-item {
   @include desktop {
      padding-left: 0;
   }
   img {
      height:40px;
      max-height: unset;
   }
}
.navbar-burger {
   height: 3rem;
   width: 3rem;
   border: 0;
   margin-top: 5px;
   outline: 0 !important;
   &:hover {
      background-color: #fff !important;
  }
}
.navbar {
   background-color: transparent;
}
.navbar-menu {
   @include desktop {
      text-align: center;
      padding-top: 1rem;
      padding-bottom: 1.3rem;

      .navbar-start>.navbar-item {
         padding: 0 !important;
      }
   }
}
#navbar {
   position: absolute;
   top: 0;
   left: 0;
   width: 100%;
   z-index: 5658;
   box-shadow: 0 1px 2px rgba(0, 0, 0, .05);

   .navbar-link {
      padding-left: 26px;
      font-weight: 500;
      color: $black;
      font-family: $primary-font;
      text-transform: capitalize;
      font-size: 18px;
      padding: 8px !important;
      transition: all .25s ease;
      &:hover {
         color: $primary-color !important;
         background-color: transparent !important;
      }
   }
}

.navbar-item.has-dropdown:hover .navbar-link {
   background-color: transparent !important;
   color: $primary-color !important;
}

.navbar-brand {
   font-weight: 600;

}

.navbar-dropdown {
   visibility: hidden;
   filter: alpha(opacity=0);
   opacity: 0;
   transition: all .2s ease-in, visibility 0s linear .2s, transform .2s linear;
   -webkit-transition: all .2s ease-in, visibility 0s linear .2s, -webkit-transform .2s linear;
   -o-transition: all .2s ease-in, visibility 0s linear .2s, -o-transform .2s linear;
   -ms-transition: all .2s ease-in, visibility 0s linear .2s, -ms-transform .2s linear;
   width: 250px;
   padding: 8px 0;
   border-radius: 0px;
   display: block;
   border: 1px solid rgba(0, 0, 0, .05);

   @include desktop {
      visibility: visible;
      filter: alpha(opacity=1);
      opacity: 1;
      display: none;
      margin-left: auto;
      margin-right: auto;
   }
}

.navbar-link::after {
   display: none !important;
}

.has-dropdown:hover .navbar-dropdown {
   visibility: visible;
   transition: all .45s ease 0s;
   opacity: 1;
}

.navbar-dropdown .navbar-item {
   padding: .7rem 1.5rem;
   text-transform: uppercase;
   font-size: 14px;

   &:hover {
      background: $primary-color !important;
      color: $light !important;
   }
}

ul.dropdown-menu li {
   padding-left: 0px !important;
}

#navbar.nav-text-white {
   .navbar-link,
   .navbar-brand {
      color: $light;
      @include desktop {
         color: black;
      }
   }
}
.navbar-item.has-dropdown {
   padding: .5rem .75rem;
}